.hero {
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: var(--color-green);
}

#hero__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(60%) saturate(130%) contrast(130%);
}

.hero__container {
    position: absolute;
    top: 0%;
    left: 0%;
    max-width: 60rem;
    width: 100%;
    height: 100vh;
    padding: 0 var(--spacing-xxl) 0 var(--spacing-xxl);
    color: white;
    text-align: left;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.hero__content {
    width: 100%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-m);
    text-align: center;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .hero__content {
        max-width: 30rem;
        align-items: flex-start;
        padding: var(--spacing-xxs) 0 var(--spacing-xxs) var(--spacing-m);
        box-sizing: border-box;
        height: fit-content;
        box-shadow: 2px 0px 0px white inset;
        text-align: left;
        margin: 0;
    }
}

@media screen and (min-width: 1440px) {
    .hero__container {
        max-width: 60rem;
        padding-left: 20rem;
        padding-right: 0;
    }
}