.playground {
    position: relative;
    display: flex;
    background-color: var(--color-light-gray-1);
    overflow: hidden;
    align-items: center;
}

.playground__text {
    z-index: 10;
    background-color: var(--color-light-gray-1);
    padding: var(--spacing-m) var(--spacing-m) var(--spacing-m) var(--spacing-xxl);
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.playground__text p {
    font-weight: 900;
}

.playground__logos {
    position: absolute;
    display: flex;
    animation: move 20s linear infinite;
}

.playground__logos img {
    margin-left: var(--spacing-xxl);
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 768px) {
    .playground__logos {
        animation: move 10s linear infinite;
    }
}