.cta {
    background-image: url('../../../assets/images/green-y.svg');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-green);
    padding: var(--spacing-xxxl) var(--spacing-xxl);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-m);
}

.cta h2 {
    text-transform: uppercase;
}

.cta__title {
    max-width: 30rem;
}

@media screen and (min-width: 768px) {
    .cta {
        padding: var(--spacing-xxxl);
    }

    .cta__title {
        max-width: 40rem;
    }
}