.intro__section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.intro__text {
    background-color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: var(--spacing-xxl);
    gap: var(--spacing-m);
    box-sizing: border-box;
}

.intro__image {
    position: relative;
    overflow: hidden;
    display: flex;
}

.intro__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.intro__title {
    color: var(--color-light-green);
}

.services__section {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-dark-gray-1);
    padding: var(--spacing-xxl);
    gap: var(--spacing-m);
}

@media (max-width: 768px) {
    .intro__section {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .intro__text {
        order: 1;
    }

    .intro__image {
        order: 2;
    }

    .services__section {
        flex-direction: column;
    }
}