.about-us #navigation__about-us {
    box-shadow: 0px -5px 0px var(--color-light-green) inset;
}

.founders__section {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-light-gray-0);
    padding: 0 var(--spacing-xxl);
}

.founders__section>* {
    flex: 1;
}

.founders__content {
    background: var(--color-light-gray-0);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.founders__title {
    color: var(--color-light-green);
    padding: var(--spacing-l) var(--spacing-l) 0 var(--spacing-l);
}

.founders__description {
    padding: var(--spacing-m) var(--spacing-l) var(--spacing-l) var(--spacing-l);
}

.about__section {
    display: flex;
    width: 100%;
}

.about__text {
    background-color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: var(--spacing-xxl);
    gap: var(--spacing-m);
}

.about__image {
    position: relative;
    overflow: hidden;
    display: flex;
}

.about__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about__title {
    color: var(--color-light-green);
}

.about__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs);
}

.about__pilars {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-m);
}

@media (max-width: 768px) {
    .founders__section {
        flex-direction: column-reverse;
        padding: 0;
    }

    .founders__title {
        padding: var(--spacing-xl) var(--spacing-xl) 0 var(--spacing-xl);
    }

    .founders__description {
        padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    }

    .about__section {
        flex-direction: column;
    }
}