.partners-section {
    padding: var(--spacing-xxxl) var(--spacing-xxl);
    background-color: var(--color-dark-gray-2);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-l);
}

.partners-section > * {
    width: 100%;
    max-width: 60rem;
}

.partners-section__logos {
    flex-wrap: wrap;
    padding: var(--spacing-m);
    background-color: var(--color-dark-gray-1);
    display: flex;
    gap: var(--spacing-m);
    justify-content: center;
    align-items: center;
    box-shadow: 0.75rem 0.75rem 0px 0px var(--color-green);
    list-style-type: none;
    width: fit-content;
}

.partners-section__logos li, .partners-section__logos a {
    height: var(--spacing-m);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
}

.partners-section__logos img {
    height: 100%;
    object-fit: contain;
}

@media screen and (min-width: 768px) {
    .partners-section {
        padding: var(--spacing-xxl);
    }
}