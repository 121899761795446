@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa-ExtraLight.woff') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa-Heavy.woff') format('truetype');
    font-weight: 900;
    font-style: normal;
}
