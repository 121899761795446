:root {
    --spacing-xxs: 0.5rem;
    --spacing-xs: 0.75rem;
    --spacing-s: 0.75rem;
    --spacing-m: 1rem;
    --spacing-l: 1rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 2rem;
    --spacing-xxxl: 6rem;

    --font-xs: 0.75rem;
    --font-s: 1rem;
    --font-m: 1rem;
    --font-l: 1.25rem;
    --font-xl: 1.25rem;
    --font-xxl: 1.75rem;
}

@media screen and (min-width: 768px) {
    :root {
        --spacing-xxs: 0.5rem;
        --spacing-xs: 0.75rem;
        --spacing-s: 1rem;
        --spacing-m: 1rem;
        --spacing-l: 2rem;
        --spacing-xl: 3rem;
        --spacing-xxl: 3rem;
        --spacing-xxxl: 6rem;

        --font-xs: 0.75rem;
        --font-s: 1rem;
        --font-m: 1.25rem;
        --font-l: 1.5rem;
        --font-xl: 1.75rem;
        --font-xxl: 2.5rem;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --spacing-xxs: 0.5rem;
        --spacing-xs: 0.75rem;
        --spacing-s: 1rem;
        --spacing-m: 1.5rem;
        --spacing-l: 2rem;
        --spacing-xl: 3rem;
        --spacing-xxl: 4rem;
        --spacing-xxxl: 6rem;

        --font-xs: 0.75rem;
        --font-s: 1rem;
        --font-m: 1.25rem;
        --font-l: 1.5rem;
        --font-xl: 1.75rem;
        --font-xxl: 2.5rem;
    }
}

@media screen and (min-width: 1440px) {
    :root {
        --spacing-xxs: 0.5rem;
        --spacing-xs: 0.75rem;
        --spacing-s: 1rem;
        --spacing-m: 1.5rem;
        --spacing-l: 4rem;
        --spacing-xl: 4rem;
        --spacing-xxl: 8rem;
        --spacing-xxxl: 10rem;

        --font-xs: 1rem;
        --font-s: 1.25rem;
        --font-m: 1.5rem;
        --font-l: 1.75rem;
        --font-xl: 2rem;
        --font-xxl: 3rem;
    }
}