body,
p,
hr,
ul {
    font-family: 'Nexa', sans-serif;
    font-weight: 100;
    line-height: 120%;
    font-size: var(--font-m);
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;
}

a {
    font-family: 'Nexa', sans-serif;
    font-weight: 100;
    line-height: 120%;
    font-size: var(--font-s);
    text-decoration: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;
    color: var(--color-light-green);
    font-weight: 900;
}

h1 {
    font-family: 'Nexa';
    font-size: var(--font-xxl);
    font-weight: 900;
    line-height: 110%;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;
}

h2 {
    font-family: 'Nexa';
    font-size: var(--font-xl);
    font-weight: 900;
    line-height: 110%;
    margin-block-start: 0;
    margin-block-end: 0;
}

h3 {
    font-family: 'Nexa';
    font-size: var(--font-l);
    font-weight: 900;
    line-height: 110%;
    margin-block-start: 0;
    margin-block-end: 0;
}

body {
    background-color: black;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    animation: fadeInUp 0.5s ease-in;
}

.highlights {
    background-color: var(--color-light-green);
    padding: 0 0.25rem;
    color: white;
    font-weight: 900;
    width: fit-content;
    margin-bottom: var(--spacing-xxs);
}
