.contact {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.contact-info {
    flex-grow: 1;
    display: flex;
    height: 100%;
    gap: var(--spacing-xxl);
    padding: var(--spacing-xxl);
    background: url('../../assets/images/big-y.svg'), white;
    background-position: right;
    background-size: auto 100%, auto 100%;
    background-repeat: no-repeat;
}

.contact-form__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-m);
    flex: 3;
    max-width: 40rem;
}

.contact-info h2 {
    color: var(--color-light-green);
    max-width: 20rem;
}

.contact-info__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    flex: 2;
}

.contact-info__content a {
    display: inline-block;
    color: black;
    font-weight: 100;
    line-height: 120%;
    font-size: var(--font-m);
    text-decoration: underline;
    margin-bottom: var(--spacing-m);
}

@media (max-width: 768px) {
    .contact-info {
        flex-direction: column;
    }

    .contact-info__content {
        background: var(--color-light-green);
        padding: var(--spacing-xl);
        color: white;
    }

    .contact-info__content a {
        color: white;
        margin-bottom: var(--spacing-xl);
    }

    .contact-info__content > .highlights {
        background-color: white;
        color: var(--color-light-green);
    }
}