.button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
}

.primary {
  background-color: white;
  color: black;
}

.primary__dark {
  background-color: black;
  color: white;
}

.primary:hover, .primary__dark:hover {
  background-color: var(--color-light-green);
  color: white;
}

.secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.secondary__dark {
  color: black;
  border: 2px solid black;
}

.secondary:hover, .secondary__dark:hover {
  background-color: transparent;
  color: var(--color-light-green);
  border: 2px solid var(--color-light-green);
}

