.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-xs);
    width: 100%;
}

.contact-form input,
.contact-form textarea {
    font-family: 'Nexa', sans-serif;
    font-weight: 100;
    line-height: 120%;
    font-size: var(--font-m);
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;
    padding: var(--spacing-xs);
    width: 100%;
    box-sizing: border-box;
    resize: none;
    border: 2px solid var(--color-light-gray-1);
}

.contact-form textarea {
    height: 10rem;
}
.contact-form input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

.contact-form input[type="submit"]:hover {
    background-color: #45a049;
}
