.founder-card {
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.founder-card__image {
    width: 100%;
    height: 20rem;
    flex-shrink: 0;
    overflow: hidden;
}

.founder-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.founder-card__text {
    flex-grow: 1;
    padding: var(--spacing-l);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    height: 100%;
}

.founder-card__title {
    font-weight: 900;
}

.founder-card__text a {
    margin-top: auto;
}

@media screen and (max-width: 768px) {
    .founder-card {
        flex-direction: row;
        text-align: left;
    }

    .founder-card__image {
        width: 50%;
        height: auto;
    }

    .founder-card__text {
        padding: var(--spacing-xl);
        margin-top: auto;
        margin-bottom: auto;
    }

    .founder-card--reverse {
        flex-direction: row-reverse;
    }
}

@media screen and (min-width: 1440px) {
    .founder-card__image {
        height: 35rem;
    }
}