.service-card {
    background: var(--color-dark-gray-2);
    color: white;
    flex: 1;
    transition: box-shadow 0.3s ease;
}

.service-card__image {
    width: 100%;
    object-fit: cover;
    height: 10rem;
}

.service-card__text {
    padding: var(--spacing-m);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    height: fit-content;
}

.service-card__description {
    color: var(--color-gray);
}

.service-card__highlights {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs);
}

.service-card__highlights li {
    background-color: var(--color-green);
    padding: 0 0.25rem;
}

@media screen and (min-width: 768px) {
    .service-card:hover {
        box-shadow: 0.75rem 0.75rem 0px 0px var(--color-light-green);
    }

    .service-card__image {
        height: 20rem;
    }
}