.page-description {
    display: flex;
    color: white;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
    background: linear-gradient(90deg, rgba(0, 115, 71, 0.00) 50%, rgba(0, 115, 71, 0.20) 100%), black;
    box-sizing: border-box;
}

.page-description__container {
    padding: var(--spacing-xxl);
}

.page-description__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--spacing-xxs) 0 var(--spacing-xxs) var(--spacing-m);
    gap: var(--spacing-m);
    box-shadow: 2px 0px 0px white inset;
    max-width: 50rem;
}

.page-description__image {
    flex: 0 0 25%;
    max-width: 40rem;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    display: none;
}

.page-description__image img {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

@media screen and (min-width: 768px) {
    .page-description__image {
        display: block;
    }
}