.footer {
    background: black;
    color: white;
    padding: var(--spacing-l) var(--spacing-xxl);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-m);
}

.footer__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.footer__logo {
    width: 100%;
    height: auto;
    max-width: 200px;
}

.footer__links {
    display: flex;
    align-items: center;
    gap: var(--spacing-m);
}

.footer__linkedin {
    width: 30px;
    height: 30px;
}

.footer__linkedin-icon {
    width: 100%;
    height: 100%;
}

.footer__divider {
    width: 100%;
    border: none;
    height: 2px;
    background-color: var(--color-dark-gray-0);
}

.footer p {
    font-size: var(--font-xs);
}

.footer a {
    color: white;
    text-decoration: none;
    font-weight: 800;
}

.footer a:hover {
    color: var(--color-light-green);
}


@media (max-width: 768px) {
    .footer__logo {
        max-width: 125px;
    }

    .footer__container {
        flex-direction: column;
        gap: var(--spacing-s);
    }

    .footer__links {
        flex-direction: column;
        gap: var(--spacing-s);
    }
}