.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0 var(--spacing-s);
  z-index: 999;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}

.header a {
  color: white;
  text-decoration: none;
  font-weight: 800;
  display: flex;
  align-items: center;
}

.navigation ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navigation__link:hover {
  box-shadow: 0px -5px 0px var(--color-light-green) inset;
}

.navigation__link:hover a {
  color: var(--color-light-green);
}

.navigation__link:active {
  color: var(--color-light-green);
}

.navigation li {
  align-self: stretch;
  margin-right: var(--spacing-l);
  display: flex;
  align-items: center;
  padding: var(--spacing-s) 0;
}

.navigation ul,
li,
button {
  animation: none !important;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.hamburger span {
  width: 2rem;
  height: 0.25rem;
  background: white;
  transition: all 0.3s linear;
}

.header.open {
  transform: translateX(0);
}

.navigation__list {
  display: flex;
}

.navigation__list.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .header {
    padding: var(--spacing-s);
  }

  .hamburger {
    display: flex;
  }

  .navigation__list {
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 75vw;
    background: black;
    padding: var(--spacing-xl) !important;
    box-sizing: border-box;
  }

  .navigation li {
    padding: var(--spacing-l) 0;
  }

  .navigation li a {
    font-size: var(--font-xl) !important;
  }
}