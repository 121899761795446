:root {
    --color-green: #00331E; 
    --color-light-green: #007347; 
    --color-dark-gray-2: #0D0D0D;
    --color-dark-gray-1: #141414;
    --color-dark-gray-0: #212121;
    --color-gray: #B6B7BB;
    --color-light-gray-2: #D9D9D9;
    --color-light-gray-1: #E6E6E6;
    --color-light-gray-0: #F2F2F2;
  }
  